<template>
  <div class="p-index">
    <div :class="{ 'm-carousel--hide': !carouselInitComplete.headline }" class="m-carousel">
      <div class="swiper-container m-carousel-headline">
        <div class="swiper-wrapper">
          <template v-for="item in datas.carousel">
            <div v-if="item.ad_id && !isCarouselAdEmpty" :id="item.ad_id" :key="item.ad_id" class="swiper-slide m-carousel-headline__ad" />
            <nuxt-link
              v-else-if="item.article_id"
              :key="item.article_id"
              :to="`/article/${item.article_id}/`"
              class="swiper-slide m-carousel-headline__item"
              title="前往新聞頁"
              tabindex="-1"
            >
              <div class="m-carousel__category">
                {{ item.main_category }}
              </div>
              <div v-if="item.type !== 'general'" class="e-type--bg">
                <span :class="articleType(item).style" class="e-type">
                  {{ articleType(item).text }}
                </span>
              </div>
              <img
                width="858"
                height="452"
                :src="item.cover.md"
                :alt="item.caption"
                class="m-carousel-headline__img"
                onerror="this.src='https://static.ctwant.com/images/dist/default_cover_md.png'"
              >
              <div class="m-carousel-headline__title-container">
                <h2 class="m-carousel-headline__title">
                  {{ item.title }}
                </h2>
              </div>
            </nuxt-link>
          </template>
        </div>
        <button slot="button-prev" class="swiper-button-prev" title="上一則新聞輪播">
          <img src="https://static.ctwant.com/images/dist/arrow-point-to-left.svg" alt="輪播上一則圖示">
        </button>
        <button slot="button-next" class="swiper-button-next" title="下一則新聞輪播">
          <img src="https://static.ctwant.com/images/dist/arrow-point-to-right.svg" alt="輪播下一則圖示">
        </button>
      </div>
    </div>
    <div class="l-ad__wrapper l-ad--hide-laptop mb-6">
      <div id="div-gpt-ad-1562665396118-0" ref="div-gpt-ad-1562665396118-0" style="width: 970px; height: 90px;" />
    </div>
    <!-- 熱門議題 -->
    <div v-if="hotTopicsReselected.length > 0" class="l-hottopic container">
      <div class="l-hottopic__title">
        <h2>熱門議題</h2>
      </div>
      <hr class="l-hottopic__hr">
      <ul id="hottopic">
        <li v-for="topic in hotTopicsReselected" :key="topic.topic_id">
          <nuxt-link :to="`/topic/${topic.topic_id}/`" title="前往專題頁面">
            {{ topic.title }}
          </nuxt-link>
        </li>
      </ul>
    </div>
    <div class="p-index__body container">
      <div class="p-index__content">
        <div class="l-section">
          <h2 class="l-section__title-with-hr">
            社會
          </h2>
          <hr>
          <div class="l-section__content">
            <div class="row">
              <nuxt-link v-for="i in datas.categories['社會']" :key="i.article_id" :to="`/article/${i.article_id}/`" class="m-card col-lg-4 col-md-6 col-12" title="前往新聞頁">
                <div v-if="i.type !== 'general'" class="e-type--bg m-card__type">
                  <span :class="articleType(i).style" class="e-type">
                    {{ articleType(i).text }}
                  </span>
                </div>
                <LazyImage :src="selectImageSize(i.cover)" :alt="i.caption" class="m-card__img" width="250" height="131" />
                <h3 class="m-card__title m-card--mle-2">
                  {{ i.title }}
                </h3>
              </nuxt-link>
            </div>
            <nuxt-link :to="`/category/社會/`" class="e-btn-more" title="查看更多社會新聞">
              看更多
              <svg class="e-btn__arrow-r" viewBox="0 0 8 15">
                <title>查看更多社會新聞</title>
                <path d="M7.712 8.243l-6.034 6.45a.938.938 0 01-1.39 0 1.102 1.102 0 010-1.486L5.628 7.5.287 1.793a1.102 1.102 0 010-1.485.939.939 0 011.39 0l6.034 6.45c.192.205.288.473.288.742s-.096.538-.288.743z" fill-rule="nonzero" />
              </svg>
            </nuxt-link>
          </div>
        </div>
        <!-- 手機側邊欄 -->
        <SideBarForMobile :data="datas" />
        <!-- 手機側邊欄 -->
        <div class="l-section">
          <h2 class="l-section__title-with-hr">
            娛樂
          </h2>
          <hr>
          <div class="l-section__content">
            <div class="row">
              <nuxt-link v-for="i in datas.categories['娛樂']" :key="i.article_id" :to="`/article/${i.article_id}/`" class="m-card col-lg-4 col-md-6 col-12" title="前往新聞頁">
                <div v-if="i.type !== 'general'" class="e-type--bg m-card__type">
                  <span :class="articleType(i).style" class="e-type">
                    {{ articleType(i).text }}
                  </span>
                </div>
                <LazyImage :src="selectImageSize(i.cover)" :alt="i.caption" class="m-card__img" width="250" height="131" />
                <h3 class="m-card__title m-card--mle-2">
                  {{ i.title }}
                </h3>
              </nuxt-link>
            </div>
            <nuxt-link :to="`/category/娛樂/`" class="e-btn-more" title="查看更多娛樂新聞">
              看更多
              <svg class="e-btn__arrow-r" viewBox="0 0 8 15">
                <title>查看更多娛樂新聞</title>
                <path d="M7.712 8.243l-6.034 6.45a.938.938 0 01-1.39 0 1.102 1.102 0 010-1.486L5.628 7.5.287 1.793a1.102 1.102 0 010-1.485.939.939 0 011.39 0l6.034 6.45c.192.205.288.473.288.742s-.096.538-.288.743z" fill-rule="nonzero" />
              </svg>
            </nuxt-link>
          </div>
        </div>
        <!-- 最新影音 -->
        <div class="l-section">
          <h2 class="l-section__title-with-hr">
            最新影音
          </h2>
          <hr>
          <div class="l-section__content">
            <div class="row">
              <nuxt-link
                v-for="i in datas.videos"
                :key="i.video_id"
                :to="`/video/${i.video_id}/`"
                class="m-card col-lg-4 col-md-6 col-12"
                title="前往影音新聞頁"
              >
                <div class="e-video__img-container">
                  <LazyImage :src="i.cover.md" :alt="i.caption" class="m-card__img" width="250" height="131" />
                  <div class="e-video__icon-wrapper">
                    <div class="e-video__icon" />
                  </div>
                </div>
                <h3 class="m-card__title  m-card--mle-2">
                  {{ i.title }}
                </h3>
                <time :datetime="i.publish_at" class="e-time mt-1">
                  <img src="https://static.ctwant.com/images/dist/clock.svg" class="m-card__clock" width="14" height="14" alt="發佈時間圖示">
                  {{ i.publish_at }}
                </time>
              </nuxt-link>
            </div>
            <nuxt-link :to="`/video/`" class="e-btn-more" title="前往影音新聞首頁">
              看更多
              <svg class="e-btn__arrow-r" viewBox="0 0 8 15">
                <title>前往影音新聞首頁</title>
                <path d="M7.712 8.243l-6.034 6.45a.938.938 0 01-1.39 0 1.102 1.102 0 010-1.486L5.628 7.5.287 1.793a1.102 1.102 0 010-1.485.939.939 0 011.39 0l6.034 6.45c.192.205.288.473.288.742s-.096.538-.288.743z" fill-rule="nonzero" />
              </svg>
            </nuxt-link>
          </div>
        </div>
        <!-- 最新影音 -->
        <div class="l-section">
          <h2 class="l-section__title-with-hr">
            財經
          </h2>
          <hr>
          <div class="l-section__content">
            <div class="row">
              <nuxt-link v-for="i in articleShower(datas.categories['財經'], 3, 4)" :key="i.article_id" :to="`/article/${i.article_id}/`" class="m-card col-sm-4 col-6" title="前往新聞頁">
                <div v-if="i.type !== 'general'" class="e-type--bg m-card__type">
                  <span :class="articleType(i).style" class="e-type">
                    {{ articleType(i).text }}
                  </span>
                </div>
                <LazyImage :src="selectImageSize(i.cover)" :alt="i.caption" class="m-card__img" width="250" height="131" />
                <h3 class="m-card__title m-card--mle-2">
                  {{ i.title }}
                </h3>
              </nuxt-link>
            </div>
            <nuxt-link :to="`/category/財經/`" class="e-btn-more" title="查看更多財經新聞">
              看更多
              <svg class="e-btn__arrow-r" viewBox="0 0 8 15">
                <title>查看更多財經新聞</title>
                <path d="M7.712 8.243l-6.034 6.45a.938.938 0 01-1.39 0 1.102 1.102 0 010-1.486L5.628 7.5.287 1.793a1.102 1.102 0 010-1.485.939.939 0 011.39 0l6.034 6.45c.192.205.288.473.288.742s-.096.538-.288.743z" fill-rule="nonzero" />
              </svg>
            </nuxt-link>
          </div>
        </div>
        <div class="l-section">
          <h2 class="l-section__title-with-hr">
            政治
          </h2>
          <hr>
          <div class="l-section__content">
            <div class="row">
              <nuxt-link v-for="i in articleShower(datas.categories['政治'], 3, 4)" :key="i.article_id" :to="`/article/${i.article_id}/`" class="m-card col-sm-4 col-6" title="前往新聞頁">
                <div v-if="i.type !== 'general'" class="e-type--bg m-card__type">
                  <span :class="articleType(i).style" class="e-type">
                    {{ articleType(i).text }}
                  </span>
                </div>
                <LazyImage :src="selectImageSize(i.cover)" :alt="i.caption" class="m-card__img" width="250" height="131" />
                <h3 class="m-card__title m-card--mle-2">
                  {{ i.title }}
                </h3>
              </nuxt-link>
            </div>
            <nuxt-link :to="`/category/政治/`" class="e-btn-more" title="查看更多政治新聞">
              看更多
              <svg class="e-btn__arrow-r" viewBox="0 0 8 15">
                <title>查看更多政治新聞</title>
                <path d="M7.712 8.243l-6.034 6.45a.938.938 0 01-1.39 0 1.102 1.102 0 010-1.486L5.628 7.5.287 1.793a1.102 1.102 0 010-1.485.939.939 0 011.39 0l6.034 6.45c.192.205.288.473.288.742s-.096.538-.288.743z" fill-rule="nonzero" />
              </svg>
            </nuxt-link>
          </div>
        </div>
        <div class="l-section">
          <h2 class="l-section__title-with-hr">
            漂亮
          </h2>
          <hr>
          <div class="l-section__content">
            <div class="row">
              <nuxt-link v-for="i in articleShower(datas.categories['漂亮'], 3, 4)" :key="i.article_id" :to="`/article/${i.article_id}/`" class="m-card col-sm-4 col-6" title="前往新聞頁">
                <div v-if="i.type !== 'general'" class="e-type--bg m-card__type">
                  <span :class="articleType(i).style" class="e-type">
                    {{ articleType(i).text }}
                  </span>
                </div>
                <LazyImage :src="selectImageSize(i.cover)" :alt="i.caption" class="m-card__img" width="250" height="131" />
                <h3 class="m-card__title m-card--mle-2">
                  {{ i.title }}
                </h3>
              </nuxt-link>
            </div>
            <nuxt-link :to="`/category/漂亮/`" class="e-btn-more" title="查看更多漂亮新聞">
              看更多
              <svg class="e-btn__arrow-r" viewBox="0 0 8 15">
                <title>查看更多漂亮新聞</title>
                <path d="M7.712 8.243l-6.034 6.45a.938.938 0 01-1.39 0 1.102 1.102 0 010-1.486L5.628 7.5.287 1.793a1.102 1.102 0 010-1.485.939.939 0 011.39 0l6.034 6.45c.192.205.288.473.288.742s-.096.538-.288.743z" fill-rule="nonzero" />
              </svg>
            </nuxt-link>
          </div>
        </div>
        <div class="l-section">
          <h2 class="l-section__title-with-hr">
            生活
          </h2>
          <hr>
          <div class="l-section__content">
            <div class="row">
              <nuxt-link v-for="i in articleShower(datas.categories['生活'], 3, 4)" :key="i.article_id" :to="`/article/${i.article_id}/`" class="m-card col-sm-4 col-6" title="前往新聞頁">
                <div v-if="i.type !== 'general'" class="e-type--bg m-card__type">
                  <span :class="articleType(i).style" class="e-type">
                    {{ articleType(i).text }}
                  </span>
                </div>
                <LazyImage :src="selectImageSize(i.cover)" :alt="i.caption" class="m-card__img" width="250" height="131" />
                <h3 class="m-card__title m-card--mle-2">
                  {{ i.title }}
                </h3>
              </nuxt-link>
            </div>
            <nuxt-link :to="`/category/生活/`" class="e-btn-more" title="查看更多生活新聞">
              看更多
              <svg class="e-btn__arrow-r" viewBox="0 0 8 15">
                <title>查看更多生活新聞</title>
                <path d="M7.712 8.243l-6.034 6.45a.938.938 0 01-1.39 0 1.102 1.102 0 010-1.486L5.628 7.5.287 1.793a1.102 1.102 0 010-1.485.939.939 0 011.39 0l6.034 6.45c.192.205.288.473.288.742s-.096.538-.288.743z" fill-rule="nonzero" />
              </svg>
            </nuxt-link>
          </div>
        </div>
      </div>
      <!-- 側邊欄 -->
      <SideBar :data="datas" />
      <!-- 側邊欄 -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Swiper, Navigation, Autoplay } from 'swiper/js/swiper.esm'
import articleHelpers from '@/utils/articleHelpers.js'
import adTabIndexRemove from '@/utils/adTabIndexRemove.js'
import LazyImage from '@/components/LazyImage.vue'
import SideBar from '@/components/SideBar.vue'
import SideBarForMobile from '@/components/SideBarForMobile.vue'

Swiper.use([Navigation, Autoplay])
export default {
  name: 'Index',
  components: {
    LazyImage,
    SideBar,
    SideBarForMobile
  },
  mixins: [articleHelpers, adTabIndexRemove],
  async asyncData({ $axios, error }) {
    const api = $axios.defaults.baseURL
    const url = `${api}/home`
    try {
      const data = await $axios.$get(url)
      return { datas: data.data }
    } catch (e) {
      console.error('Index', {
        code: e.response.data.code,
        message: e.response.data.message,
        method: e.response.config.method,
        url: e.response.config.url
      })
      error({ statusCode: 404 })
    }
  },
  data() {
    return {
      datas: null,
      carouselInitComplete: {
        headline: false,
        defaultShowCurrent: 2
      },
      isCarouselAdEmpty: false,
      hotnewsIndex: 0,
      componentScreenWidth: 0,
      swiper: null
    }
  },
  head() {
    const WebSite = {
      '@context': 'http://schema.org',
      '@type': 'Website',
      name: 'CTWANT',
      url: 'https://www.ctwant.com',
      sameAs: [
        'https://www.facebook.com/want.ctw/',
        'https://www.youtube.com/channel/UCZtUbkty-OfR4_AQ4B0GtQA',
        'https://t.me/ctwant'
      ],
      potentialAction: {
        '@type': 'SearchAction',
        target: 'https://www.ctwant.com/search/{search_term_string}/',
        'query-input': 'required name=search_term_string'
      }
    }
    const OrganizationLogo = {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      url: 'https://www.ctwant.com',
      logo: 'https://static.ctwant.com/images/dist/logo_446x112.png'
    }
    const HotNewsListItem = {
      '@context': 'http://schema.org',
      '@type': 'ItemList',
      itemListElement: this.datas.popular.map((item, index) => {
        return {
          '@type': 'ListItem',
          position: index + 1,
          name: item.title,
          image: item.cover.md,
          url: `https://www.ctwant.com/article/${item.article_id}/`
        }
      }).slice(0, 5)
    }

    return {
      link: [
        { hid: 'alternate', rel: 'alternate', hreflang: 'zh-Hant', href: 'https://www.ctwant.com' },
        { hid: 'canonical', rel: 'canonical', href: 'https://www.ctwant.com' }
      ],
      __dangerouslyDisableSanitizers: ['script'],
      script: [
        { innerHTML: JSON.stringify(WebSite), type: 'application/ld+json' },
        { innerHTML: JSON.stringify(OrganizationLogo), type: 'application/ld+json' },
        { innerHTML: JSON.stringify(HotNewsListItem), type: 'application/ld+json' }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'WIDTH_PAD',
      'WIDTH_MOBILE',
      'screenWidth',
      'isMobile'
    ]),
    articleShower() {
      const vm = this
      const mobileWidth = this.WIDTH_MOBILE

      return function (data, pc, mobile = pc) {
        if (vm.componentScreenWidth > mobileWidth) {
          return data.slice(0, pc)
        } else {
          return data.slice(0, mobile)
        }
      }
    },
    selectImageSize() {
      const sw = this.componentScreenWidth
      return function (imageArr) {
        let imgUrl = null

        if (sw <= this.WIDTH_PAD) {
          imgUrl = imageArr.sm
        } else {
          imgUrl = imageArr.md
        }
        return imgUrl
      }
    },
    hotTopicsReselected() {
      if (this.datas.hot_topics && this.isMobile) {
        return this.datas.hot_topics.filter((item, index) => (index < 4))
      } else if (this.datas.hot_topics) {
        return this.datas.hot_topics
      } else {
        return []
      }
    }
  },
  mounted() {
    this.componentScreenWidth = this.screenWidth || window.screen.width
    this.$nextTick(() => {
      this.initSwiper()
      this.$store.dispatch('ad/displayAd')
      this.$store.dispatch('ad/registerAndDisplayPopupAd')
      this.checkIsCarouselAdEmpty()
      this.$gtm.push({
        event: 'domMounted',
        pageUrl: this.$route.fullPath,
        pageTitle: 'CTWANT'
      })

      if (this.isMobile === false) {
        this.$_adTabIndexRemoveFn([
          this.$refs['div-gpt-ad-1562665396118-0']
        ])
      }
    })
  },
  methods: {
    initSwiper() {
      const vm = this
      this.swiper = new Swiper('.m-carousel-headline', {
        slidesPerView: 'auto',
        centeredSlides: true,
        spaceBetween: 10,
        autoplay: {
          delay: 3000
        },
        loop: true,
        loopedSlides: this.carouselInitComplete.defaultShowCurrent,
        runCallbacksOnInit: false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        on: {
          init() {
            vm.carouselInitComplete.headline = true
          },
          imagesReady() {
            this.slideTo(vm.carouselInitComplete.defaultShowCurrent, 0, false)
            this.autoplay.start()
          }
        }
      })
    },
    checkIsCarouselAdEmpty() {
      const that = this
      googletag.cmd.push(function () {
        googletag.pubads().addEventListener('slotRenderEnded', (event) => {
          if (event.slot.getSlotElementId() !== 'div-gpt-ad-1594969509750-0') {
            return
          }
          if (event.isEmpty) {
            that.isCarouselAdEmpty = true
            that.$nextTick(() => {
              that.swiper.update()
            })
          }
        })
      })
    }
  }
}
</script>
<style lang="css">
@import 'node_modules/swiper/css/swiper.min.css';
</style>
<style lang="scss" scoped>
@import '~/assets/scss/pages/_index';
</style>
